import React from 'react';
import "./PrivacyPolicy.css"
import Navbar from '../../components/Navbar/Navbar'; // Adjust the path as necessary
import Footer from '../../components/footer/Footer'; // Adjust the path as necessary


const PrivacyPolicy = () => {
    return (
        <div>
            <Navbar />
            <div className="privacy-policy" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>

                <h1>Privacy Policy</h1>
                <p>
                    At Kalai, we are committed to protecting your privacy. This Privacy Policy
                    explains how we collect, use, and safeguard your information when you
                    visit our website or engage with our services.
                </p>

                <h2>Information We Collect</h2>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> When you register for classes or
                        contact us, we may collect personal details such as your name, email
                        address, phone number, and any other information you provide.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> We may collect information about how you
                        interact with our website, such as your IP address, browser type,
                        pages viewed, and time spent.
                    </li>
                </ul>

                <h2>How We Use Your Information</h2>
                <ul>
                    <li>To provide and manage our services.</li>
                    <li>To respond to your inquiries and improve our offerings.</li>
                    <li>To send updates, newsletters, and other promotional material.</li>
                    <li>To analyze website usage and enhance user experience.</li>
                </ul>

                <h2>Sharing Your Information</h2>
                <p>
                    We do not sell, trade, or rent your personal information to others. We
                    may share your data with trusted third parties who assist in operating
                    our website, conducting our business, or serving you, as long as those
                    parties agree to keep this information confidential.
                </p>

                <h2>Security</h2>
                <p>
                    We implement a variety of security measures to maintain the safety of
                    your personal information. However, no method of transmission over the
                    Internet is completely secure, and we cannot guarantee absolute security.
                </p>

                <h2>Cookies</h2>
                <p>
                    Our website uses cookies to enhance your experience. You can choose to
                    disable cookies through your browser settings, but this may limit some
                    functionalities of the site.
                </p>

                <h2>Your Rights</h2>
                <p>
                    You have the right to access, correct, or delete your personal
                    information. To make a request, please contact us at kalai@gmail.com.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. Any changes will be
                    posted on this page with an updated revision date.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at
                    kalai@gmail.com or call +91 797 522 5629.
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default PrivacyPolicy;
